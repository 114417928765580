import React from 'react'

import { Utils as CoreUtils } from '@azos/core'
import { CoveragesCode } from '@azos/shared/src/lib/coverages'
import {
  Coverage2PremiumInfo,
  Coverage2PremiumValue,
  Coverages2Premiums,
} from '@domain/models'

export type Collection = {
  collection: Coverage2PremiumValue[]
  suggestion: Coverage2PremiumValue
  info: Coverage2PremiumInfo
  isDiffCoverage?: boolean
}

export type SelectedCoverage = {
  code: CoveragesCode
  data: Coverage2PremiumValue
  info: Coverage2PremiumInfo
  isDiffCoverage?: boolean
}

type CalculatorContextData = {
  total: number
  //
  coverages?: Coverages2Premiums
  setCoverages: (data: Coverages2Premiums) => void
  //
  selectedCoverages: SelectedCoverage[]
  selectedProducts: CoveragesCode[]
  //
  getCollection: (code: CoveragesCode) => Collection
  getCoverageData: (
    code: CoveragesCode,
    value?: Coverage2PremiumValue,
  ) => SelectedCoverage
  selectCoverage: (
    code: CoveragesCode,
    value?: Coverage2PremiumValue,
  ) => Coverage2PremiumValue
  removeCoverage: (code: CoveragesCode) => Coverage2PremiumValue | undefined
}

type CalculatorProviderProps = {}

const CalculatorContext = React.createContext({} as CalculatorContextData)

export const CalculatorProvider: React.FCC<CalculatorProviderProps> = ({
  children,
}) => {
  const [coverages, setCoverages] = React.useState<
    Coverages2Premiums | undefined
  >()

  const [selectedCoverages, setSelectedCoverages] = React.useState<
    SelectedCoverage[]
  >([])

  const selectedProducts = React.useMemo<CoveragesCode[]>(
    () => selectedCoverages.map(item => item.code),
    [selectedCoverages],
  )

  const hasDeathCoverage = React.useMemo<boolean>(
    () =>
      selectedProducts.some(item => CoreUtils.coverages.isDeathCoverage(item)),
    [selectedProducts],
  )
  const hasFuneralAssistanceCoverage = React.useMemo<boolean>(
    () =>
      selectedProducts.some(item =>
        CoreUtils.coverages.isFuneralAssistanceCoverage(item),
      ),
    [selectedProducts],
  )

  const isOnlyOneCoverage = React.useMemo(
    () => selectedProducts.length === 1,
    [selectedProducts.length],
  )
  const isOnlyDeathCoverage = React.useMemo<boolean>(
    () => isOnlyOneCoverage && hasDeathCoverage,
    [hasDeathCoverage, isOnlyOneCoverage],
  )
  const isOnlyFuneralAssistance = React.useMemo<boolean>(
    () => isOnlyOneCoverage && hasFuneralAssistanceCoverage,
    [hasFuneralAssistanceCoverage, isOnlyOneCoverage],
  )
  const isOnlyDeathAndFuneralCoverage = React.useMemo<boolean>(
    () =>
      selectedProducts.length === 2 &&
      hasDeathCoverage &&
      hasFuneralAssistanceCoverage,
    [hasDeathCoverage, hasFuneralAssistanceCoverage, selectedProducts.length],
  )

  const total = React.useMemo<number>(
    () => selectedCoverages.reduce((acc, item) => (acc += item.data.price), 0),
    [selectedCoverages],
  )

  const getCollection = React.useCallback(
    (code: CoveragesCode): Collection => {
      const repo = coverages?.find(item => item.code === code)

      const useOnlyDeathCollection =
        (CoreUtils.coverages.isDeathCoverage(code) ||
          CoreUtils.coverages.isFuneralAssistanceCoverage(code)) &&
        (selectedProducts.length === 0 ||
          isOnlyDeathCoverage ||
          isOnlyDeathAndFuneralCoverage)

      const getSuggestion = (
        suggestion?: Coverage2PremiumValue,
      ): Coverage2PremiumValue => {
        if (
          CoreUtils.coverages.isFuneralAssistanceCoverage(code) &&
          isOnlyDeathCoverage
        ) {
          const deathCoverage = selectedCoverages.find(item =>
            CoreUtils.coverages.isDeathCoverage(code),
          )
          const funeralCoverageSuggestion =
            repo?.coverage2premiumDeathOnly?.find(
              item => item.premium === deathCoverage?.data.premium,
            )
          if (funeralCoverageSuggestion) {
            return funeralCoverageSuggestion
          }
        }
        return (
          suggestion || {
            premiumValue: 0,
            premium: 0,
            price: 0,
          }
        )
      }

      if (
        repo &&
        repo.coverage2premiumDeathOnly &&
        repo.coverage2premiumDeathOnlySuggestion &&
        useOnlyDeathCollection
      ) {
        return {
          collection: repo.coverage2premiumDeathOnly,
          suggestion: getSuggestion(repo.coverage2premiumDeathOnlySuggestion),
          info: repo.info,
          isDiffCoverage: true,
        }
      }

      return {
        collection: repo?.coverage2premium || [],
        suggestion: getSuggestion(repo?.coverage2premiumSuggestion),
        info: repo?.info || ({} as Coverage2PremiumInfo),
      }
    },
    [
      coverages,
      isOnlyDeathAndFuneralCoverage,
      isOnlyDeathCoverage,
      selectedCoverages,
      selectedProducts.length,
    ],
  )

  const getCoverageData = React.useCallback(
    (code: CoveragesCode, value?: Coverage2PremiumValue): SelectedCoverage => {
      const collection = getCollection(code)
      const { suggestion, isDiffCoverage } = collection

      const selectedCoverage = collection.collection.find(
        item => item.premium === value?.premium,
      )
      const includedCoverage = selectedCoverages.find(
        item => item.code === code,
      )

      const data = selectedCoverage || includedCoverage?.data || suggestion
      const info = collection.info

      return { code, data, info, isDiffCoverage }
    },
    [getCollection, selectedCoverages],
  )

  const selectCoverage = React.useCallback(
    (
      code: CoveragesCode,
      value?: Coverage2PremiumValue,
    ): Coverage2PremiumValue => {
      const coverage = getCoverageData(code, value)
      const index = selectedProducts.indexOf(code)

      if (index >= 0) {
        selectedCoverages[index] = coverage
        setSelectedCoverages([...selectedCoverages])
      } else {
        setSelectedCoverages(state => [
          ...state.filter(s => s.code !== code),
          coverage,
        ])
      }

      return coverage.data
    },
    [getCoverageData, selectedCoverages, selectedProducts],
  )

  const removeCoverage = React.useCallback(
    (code: CoveragesCode): Coverage2PremiumValue | undefined => {
      const coverage = selectedCoverages.find(item => item.code === code)
      setSelectedCoverages(state => state.filter(item => item.code !== code))
      return coverage?.data
    },
    [selectedCoverages],
  )

  React.useEffect(() => {
    if (coverages && selectedCoverages) {
      const codeCoverages = coverages.map(item => item.code)

      const obsoleteCoverages = selectedCoverages.filter(
        item => !codeCoverages?.includes(item.code),
      )

      if (obsoleteCoverages.length > 0) {
        obsoleteCoverages.forEach(item => {
          removeCoverage(item.code)
        })
      }
    }
  }, [coverages, removeCoverage, selectedCoverages])

  // Remove funeral assistance
  React.useEffect(() => {
    if (isOnlyFuneralAssistance) {
      const funeralCoverage = selectedCoverages.find(i =>
        CoreUtils.coverages.isFuneralAssistanceCoverage(i.code),
      )
      if (funeralCoverage) removeCoverage(funeralCoverage.code)
    }
  }, [isOnlyFuneralAssistance, removeCoverage, selectedCoverages])

  // Change diff death coverage collection
  React.useEffect(() => {
    const shouldUseOnlyDeathCollection =
      isOnlyDeathCoverage || isOnlyDeathAndFuneralCoverage

    const hasDiffCoverage =
      selectedCoverages.filter(
        item =>
          item.isDiffCoverage && CoreUtils.coverages.isDeathCoverage(item.code),
      ).length > 0

    const isPromotionAndNotOnlyDeath =
      hasDiffCoverage && !shouldUseOnlyDeathCollection

    const notPromotionAndIsOnlyDeath =
      !hasDiffCoverage && shouldUseOnlyDeathCollection

    if (isPromotionAndNotOnlyDeath || notPromotionAndIsOnlyDeath) {
      const repo = coverages?.find(item =>
        CoreUtils.coverages.isDeathCoverage(item.code),
      )

      const deathCoverage = selectedCoverages.find(item =>
        CoreUtils.coverages.isDeathCoverage(item.code),
      )

      if (repo && repo.coverage2premiumDeathOnly && deathCoverage) {
        const newCollection = getCollection(deathCoverage.code)

        const coverageByPremium = newCollection.collection.find(
          item => item.premium === deathCoverage.data.premium,
        )

        const coverageReplace = coverageByPremium || newCollection.suggestion

        selectCoverage(deathCoverage.code, coverageReplace)
      }
    }
  }, [
    coverages,
    getCollection,
    isOnlyDeathAndFuneralCoverage,
    isOnlyDeathCoverage,
    selectCoverage,
    selectedCoverages,
  ])

  // Change diff funeral coverage collection
  React.useEffect(() => {
    const shouldUseOnlyDeathCollection = isOnlyDeathAndFuneralCoverage

    const deathCoverage = selectedCoverages.find(i =>
      CoreUtils.coverages.isDeathCoverage(i.code),
    )
    const funeralCoverage = selectedCoverages.find(i =>
      CoreUtils.coverages.isFuneralAssistanceCoverage(i.code),
    )

    const valuesNotEqual =
      deathCoverage?.data.premium !== funeralCoverage?.data.premium

    const hasDiffCoverage =
      selectedCoverages.filter(
        item =>
          item.isDiffCoverage &&
          CoreUtils.coverages.isFuneralAssistanceCoverage(item.code),
      ).length > 0

    const isPromotionAndNotOnlyDeath =
      hasDiffCoverage && !shouldUseOnlyDeathCollection

    const notPromotionAndIsOnlyDeathOrDiff =
      (valuesNotEqual || !hasDiffCoverage) && shouldUseOnlyDeathCollection

    if (isPromotionAndNotOnlyDeath || notPromotionAndIsOnlyDeathOrDiff) {
      const repo = coverages?.find(item =>
        CoreUtils.coverages.isFuneralAssistanceCoverage(item.code),
      )

      if (repo && repo.coverage2premiumDeathOnly && funeralCoverage) {
        const newCollection = getCollection(funeralCoverage.code)

        const coverageByPremium = newCollection.collection.find(
          item => item.premium === deathCoverage?.data.premium,
        )

        const coverageReplace = coverageByPremium || newCollection.suggestion

        const valuesNotEqual =
          coverageReplace.premium !== funeralCoverage.data.premium

        if (valuesNotEqual) {
          selectCoverage(funeralCoverage.code, coverageReplace)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverages, isOnlyDeathAndFuneralCoverage])

  const value = {
    total,
    //
    coverages,
    setCoverages,
    //
    selectedCoverages,
    selectedProducts,
    //
    getCollection,
    getCoverageData,
    selectCoverage,
    removeCoverage,
  }

  return (
    <CalculatorContext.Provider value={value}>
      {children}
    </CalculatorContext.Provider>
  )
}

export const useCalculator = () => {
  const context = React.useContext(CalculatorContext)

  if (!context) {
    throw new Error('The useCalculator should within CalculatorProvider')
  }

  return context
}
